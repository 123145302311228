









































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Card, Dropdown, ActionMenu, Tooltip, HttpGet, HttpPost, DataTable, DataTableColumn, Searchbox, DeleteConfirmationModal, Action, CopyAsync, Badge } from '../../lib/mavejs'
import { url } from '@/functions'
import { Campaign } from '@/interfaces/models/Campaign'
import CampaignTypeBadge from '@/components/CampaignTypeBadge.vue'
import InlineErrors from '@/components/InlineErrors.vue'
import GeoCopy from '@/components/GeoCopy.vue'
import { SearchPaginationResetMixin } from '@/mixins/SearchPaginationResetMixin'

@Component({
  components: { GeoCopy, Dropdown, ActionMenu, Tooltip, InlineErrors, CampaignTypeBadge, Card, HttpGet, HttpPost, DataTable, DataTableColumn, Searchbox, DeleteConfirmationModal, Action, CopyAsync, Badge },
  methods: { url }
})
export default class Index extends Mixins(SearchPaginationResetMixin) {
  @Prop({ default: false }) refresh!: boolean;

  headers = [
    { head: '', key: 'first' },
    { head: '', key: 'second' },
    { head: 'Name', key: 'name' },
    { head: 'Description', key: 'description' },
    { head: 'Comment', key: 'comment' },
    { head: 'Date', key: 'date' },
    { head: 'Geo\'s', key: 'geos' },
    { head: 'Copy', key: '' },
    { head: '', key: 'last' }
  ]

  page = 1
  showDelete = false;
  csv = ''
  sorted = { head: 'Name', key: 'name' }

  filters = {
    search: [{ column: 'product', value: '', type: 'text' }, { column: 'campaign_type', value: '', type: 'option' }],
    order: { column: 'created_at', by: 'desc' }
  }

  campaignToDuplicate = 0;
  campaignToDelete = {
    id: 0,
    name: ''
  }

  $refs: {
    modal: DeleteConfirmationModal;
    duplicate: HttpPost;
    campaigns: HttpGet;
  }

  type (id) {
    return Campaign.types[id]
  }

  duplicate (id) {
    this.campaignToDuplicate = id
    this.$nextTick(() =>
      this.$refs.duplicate.post()
        .then(() => this.$refs.campaigns.fetch()))
  }

  paginate (currentPage) {
    this.page = currentPage
  }

  openDeleteModal (id, name) {
    this.$refs.modal.open()
    this.campaignToDelete = { id: id, name: name }
  }
}
