










import { Vue, Component, Prop } from 'vue-property-decorator'
import { DropdownBehaviour } from '../lib/mavejs'

@Component({
  mixins: [DropdownBehaviour]
})
export default class InlineErrors extends Vue {
  @Prop() errors!: Array<string>;

  isOpen = false
}
