
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { CopyAsync, Badge, Action } from '../lib/mavejs'
import { url } from '@/functions'
import { Geo } from '@/interfaces/models/Geo'

@Component({
  components: { CopyAsync, Badge, Action },
  methods: { url: url }
})
export default class GeoCopy extends Vue {
  @Prop() campaign!: number;
  @Prop() geos!: Array<Geo>;
  @Prop() errors!: boolean;

  selectedGeos: Array<Geo> = []

  /**
   * @param geo
   */
  has (geo: Geo): boolean {
    return this.selectedGeos.filter(currentGeo => currentGeo.id === geo.id).length > 0
  }

  /**
   *
   * @param geo
   */
  select (geo: Geo) {
    if (this.has(geo)) {
      this.selectedGeos = this.selectedGeos.filter(currentGeo => currentGeo.id !== geo.id)
      return
    }

    this.selectedGeos.push(geo)
  }
}
