import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export class SearchPaginationResetMixin extends Vue {
  page = 1

  @Watch('filters.search', { deep: true })
  handleSearch () {
    this.page = 1
  }
}
