








import { Vue, Component, Prop } from 'vue-property-decorator'
import { Badge } from '../lib/mavejs'

@Component({
  components: { Badge }
})
export default class CampaignTypeBadge extends Vue {
  @Prop() type: number;
  @Prop({ default: '' }) text: string;

  icon () {
    switch (this.type) {
      case 0:
        return 'videocam'
      case 1:
        return 'videocam'
      case 2:
        return 'search'
      case 3:
        return 'build'
      default:
        return 'analytics'
    }
  }

  color () {
    switch (this.type) {
      case 0:
        return ['bg-red-dark', 'text-white']
      case 1:
        return ['bg-red', 'text-white']
      case 2:
        return ['bg-primary', 'text-white']
      case 3:
        return ['bg-gray', 'text-white']
      default:
        return ['bg-white', 'text-black']
    }
  }
}
